/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Select from '../../../../elements/select/pureSelect.component';
import RangeSlider from '../../../../elements/calculatorRange/rangeSlider';
import { withinRange }  from '../../../../utilities/numberHelpers';
import { formatCurrency } from '../../../../utilities/formatters';
import {
  getAnytimeLoanRepayment,
  getSmallLoanRepayment,
  getPersonalLoanRepayment
} from '../../../../utilities/calculatorLogic';
import RichText from '../../../../elements/richText';
import { shouldInsertTooltip } from '../../../../hooks/tooltips/insertTooltip';
import TwoApplyLogoPattern from '../../../../../static/images/2ApplyLogoPattern.png';
import TwoApplyLogoPatternMobile from '../../../../../static/images/2ApplyLogoPatternMobile.png';

const StyledSectionContainer = styled.div`
  position: relative;
  padding: 0 60px;

  @media (max-width: 1580px) {
    padding: 60px 0;
  }
`;

const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  background-image: url(${TwoApplyLogoPattern});
  background-repeat: repeat-y;
  width: 300px;
  width: calc((100% - 1303px) / 2);
  height: 100%;

  @media (max-width: 1580px) {
    width: 100%;
    height: 60px;
    background-image: url(${TwoApplyLogoPatternMobile});
    background-size: 550px auto;
    background-repeat: repeat-x;
  }

  ${props =>
    props.position === 'left' &&
    css`
      left: 0px;
      background-position: center -18%;
      @media (max-width: 1820px) {
        background-position: 100% -18%;
      }
      @media (max-width: 1580px) {
        background-position: center;
      }
    `}
  ${props =>
    props.position === 'right' &&
    css`
      right: 0px;
      background-position: center;
      @media (max-width: 1820px) {
        background-position: 0 center;
      }
      @media (max-width: 1580px) {
        top: auto;
        bottom: 0;
        background-position: center;
      }
    `}
`;

const StyledContainer = styled.div`
  background-color: white;
  max-width: 1283px;
  margin: auto;
  position: relative;
  padding: 64px 0;

  @media (max-width: 1580px) {
    padding: 0 60px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.md}) {
    padding: 0 40px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 0 24px;
  }
`;

const StyledHeading = styled.h2`
  color: ${props => props.theme.colours.black};
  font-family: ${props => props.theme.font.nimbleFont};
  font-style: normal;
  font-size: 29px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 7px;

  @media (max-width: 850px) {
    margin-bottom: 24px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const CalculatorForm = styled.form`
  @media (min-width: 851px) {
    display: flex;
  }
`;

const FieldsContainer = styled.div`
  flex: 0 0 50%;
  padding: 40px 80px 40px 0;

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    padding: 24px 64px 24px 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    padding: 24px 44px 24px 0;
  }

  @media (max-width: 850px) {
    padding: 0 0 16px;
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 24px;
`;

const SelectContainer = styled.div`
  margin-top: 12px;
`;

const StyledLabel = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const LabelValues = styled.span`
  color: ${props => props.theme.colours.darkGreen};
  margin-left: 8px;
`;

const StyledDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  margin: -4px 0 8px;
  @media ${props => props.theme.mediaBreakpoints.mobile} {
    color: #828282;
  }
`;

const RepaymentDetailsContainer = styled.div`
  flex: 0 0 50%;
  align-self: flex-start;
  padding: 64px;
  background-color: ${props => props.theme.colours.greyBack};

  @media (max-width: ${props => props.theme.breakpoints.max.xl}) {
    padding: 40px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    padding: 32px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 0%;
    background-color: transparent;
  }
`;

const DetailsContainer = styled.div`
  width: 100%;
`;

const DetailsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const DetailsTitle = styled.p`
  max-width: 50%;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const DetailsValue = styled.p`
  display: flex;
  flex: 0 0 auto;
  color: ${props => props.theme.colours.nimbleRed};
  text-align: right;
  font-weight: 600;
  padding-left: 30px;
  margin: 0;
  align-items: baseline;
  font-size: 35px;
  line-height: 40px;

  @media (max-width: ${props => props.theme.breakpoints.max.lg}) {
    font-size: 30px;
    line-height: 35px;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    font-size: 20px;
    line-height: 24px;
    padding-left: 16px;
  }
`;

const DetailsValueSmall = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin-left: 2px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin: 24px auto 0;
`;

const StyledButton = styled.button`
  width: 100%;
  border-radius: 4px;
  background-color: ${props => props.theme.colours.green};
  border: none;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
  transition: 0.35s ease;
  text-transform: none;
  cursor: pointer;
  font-family: ${props => props.theme.font.nimbleFont};
  font-weight: 600;
  padding: 12px 30px;

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    font-size: 16px;
    line-height: 24px;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${props => props.theme.colours.nimbleRed};
      color: #ffffff;
    }
  }
`;
const DisclaimerText = styled.div`
  font-size: 12px;
  line-height: 16px;

  p {
    margin-bottom: 0;
  }
`;

const CompareContainer = styled.div`
  margin-top: 30px;
  text-align: center;

  @media (min-width: 851px) {
    display: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    padding: 24px;
    background-color: ${props => props.theme.colours.greyBack};
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    padding: 20px 16px;
  }
`;

const CompareTitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f;
  margin: 0 0 24px;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    margin: 0 0 12px;
  }
`;

const CompareLink = styled.a`
  width: 100%;
  border-radius: 4px;
  background-color: #ffffff;
  border: 2px solid ${props => props.theme.colours.slate};
  font-size: 18px;
  line-height: 24px;
  transition: 0.35s ease;
  text-transform: none;
  cursor: pointer;
  font-family: ${props => props.theme.font.nimbleBoldFont};
  font-weight: 400;
  padding: 12px 30px;
  box-sizing: border-box;

  @media (max-width: ${props => props.theme.breakpoints.max.sm}) {
    display: inline-block;
  }

  @media (max-width: ${props => props.theme.breakpoints.max.xs}) {
    font-size: 16px;
    line-height: 24px;
    padding: 12px;
  }
`;

const RepaymentCalculatorComplex = ({ data, trackingApplyLink }) => {
  const {
    title,
    hashId,
    creditTitle,
    creditDescription,
    loanTermTitle,
    loanTermDescription,
    defaultCreditAmount,
    bracketsData,
    creditRatingTitle,
    creditRatingDescription,
    creditRatingOptions,
    repaymentFrequencyTitle,
    repaymentFrequencyDescription,
    repaymentAmountTitle,
    interestRateTitle,
    comparisonRateTitle,
    applyLink,
    calculatorDisclaimer
  } = data;
  const disclaimerData = calculatorDisclaimer;

  const getBracketForAmount = amount => {
    return bracketsData.find(d => amount >= d.min && amount <= d.max);
  };

  const creditMin = Math.min(...bracketsData.map(o => o.min));
  const creditMax = Math.max(...bracketsData.map(o => o.max));

  const smallestStep = Math.min(...bracketsData.map(o => o.step));

  let initCreditLimit;

  if (defaultCreditAmount > creditMax) {
    initCreditLimit = creditMax;
  } else if (defaultCreditAmount < creditMin) {
    initCreditLimit = creditMin;
  } else {
    initCreditLimit = defaultCreditAmount;
  }

  const initBracket = getBracketForAmount(initCreditLimit);

  const [establishmentFee, setEstablishmentFee] = useState(
    initBracket.establishmentFee
  );
  const [interestRate, setInterestRate] = useState(initBracket.interestRate);
  const [comparisonRate, setComparisonRate] = useState(
    initBracket.comparisonRate
  );
  const [repaymentMin, setRepaymentMin] = useState(initBracket.minLoanLength);
  const [repaymentMax, setRepaymentMax] = useState(initBracket.maxLoanLength);
  const [creditStep, setCreditStep] = useState(initBracket.step);
  const [termInterval, setTermInterval] = useState(initBracket.termInterval);
  const [repaymentPeriod, setRepaymentPeriod] = useState(
    initBracket.defaultLoanLength
  );
  const [disclaimer, setDisclaimer] = useState(initBracket.disclaimer);
  const [creditLimit, setCreditLimit] = useState(initCreditLimit);
  const [oldCreditLimit, setOldCreditLimit] = useState(initCreditLimit);
  const [frequency, setFrequency] = useState('fortnight');
  const [creditRating, setCreditRating] = useState(null);
  const [repaymentAmount, setRepaymentAmount] = useState(0);
  const [loanType, setLoanType] = useState(initBracket.loanType);
  const [creditAdjusted, setCreditAdjusted] = useState(false);

  const getAmountRoundedUpToNearestStep = (amount, step) => {
    const diff = amount % step;

    if (diff !== 0) {
      if (oldCreditLimit > amount && oldCreditLimit - amount < step) {
        return amount - diff; // this will round DOWN to the nearest multiple of step
      }
      return amount + (step - diff); // this will round UP to the nearest multiple of step
    }

    return amount;
  };

  const handleChangeFrequency = event => {
    setFrequency(event.target.value);
  };

  const handleChangeCreditRating = event => {
    setCreditRating(Number(event.target.value));
  };

  const updateRepayment = async () => {
    // we do this here to make sure we have latest rates and fees
    const bracket = getBracketForAmount(creditLimit);

    if (bracket) {
      let newRepayment;

      if (bracket.loanType === 'small') {
        newRepayment = await getSmallLoanRepayment(
          creditLimit,
          repaymentPeriod
        );
      } else if (bracket.loanType === 'anytime') {
        let anytimeInterest = null;
        if (creditRating) {
          anytimeInterest = creditRating;
        } else if (interestRate) {
          anytimeInterest = interestRate;
        }
        newRepayment = await getAnytimeLoanRepayment(
          anytimeInterest,
          creditLimit,
          repaymentPeriod
        );
      } else {
        newRepayment = await getPersonalLoanRepayment(
          creditLimit,
          repaymentPeriod,
          bracket.interestRate,
          bracket.establishmentFee
        );
      }

      setRepaymentAmount(newRepayment);
    }
  };

  useEffect(() => {
    if (
      creditRatingOptions &&
      creditRatingOptions.length &&
      creditRatingOptions[0].value
    ) {
      setCreditRating(creditRatingOptions[0].value);
    }
  }, []);

  useEffect(() => {
    updateRepayment();
  }, [frequency, creditLimit, repaymentPeriod, creditRating]);

  useEffect(() => {
    const bracket = getBracketForAmount(creditLimit);

    if (bracket) {
      if (creditStep !== bracket.step) {
        setCreditStep(bracket.step);
      }

      if (repaymentMin !== bracket.minLoanLength) {
        setRepaymentMin(bracket.minLoanLength);

        if (repaymentPeriod < bracket.minLoanLength) {
          setRepaymentPeriod(bracket.minLoanLength);
        }
      }

      if (repaymentMax !== bracket.maxLoanLength) {
        setRepaymentMax(bracket.maxLoanLength);

        if (repaymentPeriod > bracket.maxLoanLength) {
          setRepaymentPeriod(bracket.maxLoanLength);
        }
      }

      if (interestRate !== bracket.interestRate) {
        setInterestRate(bracket.interestRate);
      }

      if (comparisonRate !== bracket.comparisonRate) {
        setComparisonRate(bracket.comparisonRate);
      }

      if (termInterval !== bracket.termInterval) {
        setTermInterval(bracket.termInterval);
      }

      if (establishmentFee !== bracket.establishmentFee) {
        setEstablishmentFee(bracket.establishmentFee);
      }

      if (disclaimer !== bracket.disclaimer) {
        setDisclaimer(bracket.disclaimer);
      }

      if (loanType !== bracket.loanType) {
        setLoanType(bracket.loanType);
      }

      if (creditAdjusted === false) {
        const amountRoundedUpToNearestStep = getAmountRoundedUpToNearestStep(
          creditLimit,
          bracket.step
        );

        if (creditLimit !== amountRoundedUpToNearestStep) {
          const adjustment = withinRange(
            Number.parseInt(amountRoundedUpToNearestStep, 10),
            creditMin,
            creditMax
          );
          setOldCreditLimit(adjustment);
          setCreditLimit(adjustment);
          setCreditAdjusted(true);
        } else {
          setOldCreditLimit(creditLimit);
        }
      } else {
        setCreditAdjusted(false);
      }
    }
  }, [creditLimit]);

  const handleSubmit = event => {
    if (loanType === 'anytime' && applyLink && trackingApplyLink) {
      event.preventDefault();
      window.location.href = trackingApplyLink;
    }
  };

  return (
    <StyledSectionContainer id={hashId}>
      <StyledBackground position="left" />
      <StyledBackground position="right" />
      <StyledContainer>
        <StyledHeading>{title}</StyledHeading>
        <CalculatorForm
          action={applyLink && applyLink.linkUrl}
          method="get"
          onSubmit={handleSubmit}
        >
          <FieldsContainer>
            <FieldContainer>
              <StyledLabel>
                {creditTitle}
                <LabelValues>{formatCurrency(creditLimit)}</LabelValues>
              </StyledLabel>
              {creditDescription && (
                <StyledDescription>
                  {shouldInsertTooltip(creditDescription)}
                </StyledDescription>
              )}
              <RangeSlider
                onChangeCallback={setCreditLimit}
                inputName="loanAmount"
                ariaLabel="Loan amount"
                value={creditLimit}
                sliderStep={smallestStep}
                min={creditMin}
                max={creditMax}
                formatter={formatCurrency}
                hasLabels
                labelsFormatter={formatCurrency}
              />
            </FieldContainer>
            <FieldContainer>
              <StyledLabel>
                {loanTermTitle}
                <LabelValues>
                  {`${repaymentPeriod} ${termInterval}`}
                </LabelValues>
              </StyledLabel>
              {loanTermDescription && (
                <StyledDescription>
                  {shouldInsertTooltip(loanTermDescription)}
                </StyledDescription>
              )}
              <RangeSlider
                onChangeCallback={setRepaymentPeriod}
                inputName="loanTerm"
                ariaLabel="Loan term"
                value={repaymentPeriod}
                sliderStep={1}
                min={repaymentMin}
                max={repaymentMax}
                formatter={e => `${e} ${termInterval}`}
                hasLabels
                labelsFormatter={e => `${e} ${termInterval}`}
              />
            </FieldContainer>
            {creditRatingOptions && creditRating && (
              <FieldContainer>
                <StyledLabel>
                  {shouldInsertTooltip(creditRatingTitle)}
                </StyledLabel>
                <StyledDescription>
                  {shouldInsertTooltip(creditRatingDescription)}
                </StyledDescription>
                <SelectContainer>
                  <Select
                    value={creditRating}
                    onChange={handleChangeCreditRating}
                  >
                    {creditRatingOptions.map(({ label, value }) => {
                      return (
                        <option value={value} key={label}>
                          {label}
                        </option>
                      );
                    })}
                  </Select>
                </SelectContainer>
              </FieldContainer>
            )}
            <FieldContainer>
              <StyledLabel>
                {shouldInsertTooltip(repaymentFrequencyTitle)}
              </StyledLabel>
              <StyledDescription>
                {shouldInsertTooltip(repaymentFrequencyDescription)}
              </StyledDescription>
              <SelectContainer>
                <Select onChange={handleChangeFrequency} value={frequency}>
                  <option value="week">Weekly</option>
                  <option value="fortnight">Fortnightly</option>
                  <option value="month">Monthly</option>
                </Select>
              </SelectContainer>
            </FieldContainer>
          </FieldsContainer>
          <RepaymentDetailsContainer>
            <DetailsContainer>
              <DetailsItem>
                <DetailsTitle>
                  {shouldInsertTooltip(repaymentAmountTitle)}
                </DetailsTitle>
                <DetailsValue>
                  {frequency === 'week' &&
                    repaymentAmount.week &&
                    `$${repaymentAmount.week.toFixed(0)}`}
                  {frequency === 'fortnight' &&
                    repaymentAmount.fortnight &&
                    `$${repaymentAmount.fortnight.toFixed(0)}`}
                  {frequency === 'month' &&
                    repaymentAmount.month &&
                    `$${repaymentAmount.month.toFixed(0)}`}
                  <DetailsValueSmall>{`/${frequency}`}</DetailsValueSmall>
                </DetailsValue>
              </DetailsItem>

              {creditRating && (
                <DetailsItem>
                  <DetailsTitle>
                    {shouldInsertTooltip(interestRateTitle)}
                  </DetailsTitle>
                  <DetailsValue>
                    {creditRating.toFixed(4).replace(/0{0,2}$/, '')}
                    <DetailsValueSmall>% p.a.</DetailsValueSmall>
                  </DetailsValue>
                </DetailsItem>
              )}
              {interestRate && !creditRating && (
                <DetailsItem>
                  <DetailsTitle>
                    {shouldInsertTooltip(interestRateTitle)}
                  </DetailsTitle>
                  <DetailsValue>
                    {interestRate.toFixed(4).replace(/0{0,2}$/, '')}
                    <DetailsValueSmall>% p.a.</DetailsValueSmall>
                  </DetailsValue>
                </DetailsItem>
              )}
              {comparisonRate && (
                <DetailsItem>
                  <DetailsTitle>
                    {shouldInsertTooltip(comparisonRateTitle)}
                  </DetailsTitle>
                  <DetailsValue>
                    {comparisonRate.toFixed(4).replace(/0{0,2}$/, '')}
                    <DetailsValueSmall>% p.a.</DetailsValueSmall>
                  </DetailsValue>
                </DetailsItem>
              )}
            </DetailsContainer>
            {applyLink && (
              <ButtonContainer>
                <StyledButton type="submit">{applyLink.linkText}</StyledButton>
              </ButtonContainer>
            )}
            <DisclaimerText>
              {disclaimerData && <RichText text={disclaimerData} />}
              {disclaimer && <p>{disclaimer}</p>}
            </DisclaimerText>
          </RepaymentDetailsContainer>
        </CalculatorForm>
        <CompareContainer>
          <CompareTitle>
            Looking for other products that might suit you better?
          </CompareTitle>
          <CompareLink href="/loans">Let’s compare products</CompareLink>
        </CompareContainer>
      </StyledContainer>
    </StyledSectionContainer>
  );
};

RepaymentCalculatorComplex.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    hashId: PropTypes.string,
    creditTitle: PropTypes.string,
    creditDescription: PropTypes.string,
    loanTermTitle: PropTypes.string,
    loanTermDescription: PropTypes.string,
    defaultCreditAmount: PropTypes.number,
    bracketsData: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      )
    ),
    creditRatingTitle: PropTypes.string,
    creditRatingDescription: PropTypes.string,
    creditRatingOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number
      })
    ),
    repaymentFrequencyTitle: PropTypes.string,
    repaymentFrequencyDescription: PropTypes.string,
    repaymentAmountTitle: PropTypes.string,
    interestRateTitle: PropTypes.string,
    comparisonRateTitle: PropTypes.string,
    applyLink: PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string
    }),
    calculatorDisclaimer: PropTypes.objectOf(PropTypes.string)
  }),
  trackingApplyLink: PropTypes.string
};

export default RepaymentCalculatorComplex;
